import React, { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import profilePic from '../img.png';
import Resume from '../../resume.json';
import { motion } from 'framer-motion';
import {GoogleAnalytics} from "@next/third-parties/google";

const Layout = ({ children }: { children: React.ReactNode }) => {
    // Function to adjust height for mobile browsers
    const adjustHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        adjustHeight(); // Initial adjustment
        window.addEventListener('resize', adjustHeight);
        return () => window.removeEventListener('resize', adjustHeight);
    }, []);

    return (
        <>
            <div className="portfolio-card">
                {/* Left Side */}
                <motion.div
                    className="left-side"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.5, type: 'spring' }}
                >
                    <div className="content">
                        <motion.h2
                            className="name"
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1, delay: 0.5 }}
                        >
                            {Resume.basics.name}
                        </motion.h2>
                        <motion.h1
                            className="role"
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1, delay: 0.7 }}
                        >
                            {Resume.basics.label}
                        </motion.h1>
                    </div>
                </motion.div>

                {/* Divider and Profile Picture */}
                <div
                    className="divider"
                >
                    <div className="circle">
                        <Image
                            src={profilePic}
                            alt={Resume.basics.name}
                            className="profile-pic"
                            priority={true}
                            sizes="(max-width: 768px) 100vw, 33vw"
                        />
                    </div>
                </div>

                {/* Right Side and Navigation */}
                <motion.div
                    className="right-side"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1.5 }}
                >
                    <nav className="navigation">
                        <motion.ul
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 1.8 }}
                        >
                            <li><Link href="/">Work experience</Link></li>
                            <li><Link href="/skills">Skills</Link></li>
                            <li><Link href="/photography">More</Link></li>
                            <li><Link href="mailto:hello@karpin.ee">Contact Me</Link></li>
                        </motion.ul>
                    </nav>
                    <div className="content-area">
                        {children}
                    </div>
                </motion.div>
            </div>
            <GoogleAnalytics gaId="G-YHL35LPKJJ"/>
        </>
    );
};

export default Layout;
