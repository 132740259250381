'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import Head from 'next/head';
import Layout from "@/app/layouts/Layout";

const getPageTitle = (pathname: string) => {
    switch (pathname) {
        case '/':
            return 'FrontPage';
        case '/projects':
            return 'Projects';
        default:
            return 'Page';
    }
};

const ClientLayout = ({ children, resumeName }: { children: React.ReactNode, resumeName: string }) => {
    const pathname = usePathname();
    const pageTitle = getPageTitle(pathname);

    return (
        <>
            <Head>
                <title>{`${pageTitle} - ${resumeName}`}</title>
            </Head>
            <Layout>
                {children}
            </Layout>
        </>
    );
};

export default ClientLayout;
